import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { ProjectMetaInfo } from '../../types/app.types';
import joinClassName from '../../utils/className.utils';
import CTAButton, { CTAButtonProps } from '../CTAButton/CTAButton';
// import './ProjectURLButton.scss';

type ProjectURLButtonProps = Omit<CTAButtonProps, 'title'> & {
  project: ProjectMetaInfo,
  drawLine?: 'left' | 'right' | false,
}

const ProjectURLButton: React.FC<ProjectURLButtonProps> = props => {
  const { UI } = useAppContext();
  return <CTAButton
    {...props}
    className={joinClassName('ProjectURLButton', props.className)}
    to={props.project.url}
    title={`Visit ${props.project.displayName}`}
    rel="noreferrer"
    target="_blank"
    drawLine={props.drawLine === false ? undefined : (props.drawLine ?? (UI.viewport.width > 768 ? 'left' : 'right'))}
  >Visit Site</CTAButton>
}

export default ProjectURLButton;