export enum Hashtag {
  'branding' = 'branding',
  'digitalDesign' = 'digitalDesign',
  'printDesign' = 'printDesign',
  'animations' = 'animations',
}

export const DesignHashtags = [
  Hashtag.digitalDesign,
  Hashtag.animations,
  Hashtag.branding,
  Hashtag.printDesign,
]