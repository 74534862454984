import { Observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useAnimateTextEnterByLine } from '../../hooks/useAnimateTextEnterByLine.hook';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { ProjectMetaInfoWithQueriedResources } from '../../types/app.types';
import { PageComponent } from '../../types/gatsby.types';
import joinClassName from '../../utils/className.utils';
import { disableSmoothScrollTemporarily } from '../../utils/css.utils';
import PageSection from '../PageSection/PageSection';
import PageTemplate from '../PageTemplate/PageTemplate';
import ProjectURLButton from '../ProjectURLButton/ProjectURLButton';
import StandardTextBlock from '../StandardTextBlock/StandardTextBlock';
import TricolorMorphDef from '../TricolorMorphDef/TricolorMorphDef';
import Widowless from '../Widowless/Widowless';
import './ProjectPage.scss';

type ProjectPageProps = {
  project: ProjectMetaInfoWithQueriedResources,
  sidebarExtraContent?: ReactElement,
}

const ProjectPage: PageComponent<ProjectPageProps> = props => {
  const key = `ProjectPage--${props.project.name}`;
  useOnMount(disableSmoothScrollTemporarily);
  const pageHeadingGroupRef = useObservableRef();
  useAnimateTextEnterByLine(pageHeadingGroupRef, { 
    animateTextEnter: { delay: 100 }
  });
  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      className={joinClassName('ProjectPage', key)}
      title={props.project.displayName}
      description={props.project.description}
      keywords={props.project.keywords}
      entryMeta={props.project}
      backgroundImage={props.project.pageBackgroundImage ?? props.project.featuredImage}
    >
      <TricolorMorphDef />
      <PageSection
        id="ProjectPageContentWrapper"
        as="article"
        className="ProjectPageContentWrapper"
      >

        <aside className="ProjectPageMetaInfoColumn">
          <div className="ProjectPageMetaInfoColumnContent">
            <header className="ProjectPageHeader">
              <div className="ProjectPageHeaderInner">
                <div className="ProjectPageHeaderHeadingGroup" ref={pageHeadingGroupRef} >
                  <h1 className="ProjectPageTitle">{props.project.displayName}</h1>
                  <p className="ProjectPageSubtitle"><Widowless as="strong">{props.project.subtitle}</Widowless></p>
                </div>
                <StandardTextBlock className="ProjectPageDescription" animateTextEnter={{ delay: 300 }}>
                  <Widowless>{props.project.description}</Widowless>
                </StandardTextBlock>
                {props.project.url && <ProjectURLButton project={props.project} ribbons />}
                {props.sidebarExtraContent && <div className="ProjectPageDescriptionExtraContent">
                  {props.sidebarExtraContent}
                </div>}
              </div>
            </header>
          </div>
        </aside>

        <div className="ProjectPageContent">
          {props.children}
        </div>

      </PageSection>
    </PageTemplate>
  )} />
}

export default ProjectPage;