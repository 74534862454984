import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { isIE } from '../../utils/browsers.utils';
import './YouTubeEmbed.scss';

type YouTubeEmbedProps = {
  src: string,
  title: string,
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = props => {
  const { UI } = useAppContext();
  return <Observer children={() => (
    <div className="YouTubeEmbed">
      <iframe
        src={props.src}
        title={props.title}
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
        width={isIE ? Math.min(UI.viewport.width - 30, 600) : undefined}
        height={isIE ? Math.round(Math.min(UI.viewport.width - 30, 600) * .25) : undefined}
      />
    </div>
  )} />
}

export default YouTubeEmbed;