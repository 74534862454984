export enum Sector {
  'all' = 'all',
  // 'agriculture' = 'agriculture',
  // 'aviation' = 'aviation',
  // 'construction' = 'construction',
  'eCommerceAndRetail' = 'eCommerceAndRetail',
  'eLearning' = 'eLearning',
  'energy' = 'energy',
  'entertainmentAndEvents' = 'entertainmentAndEvents',
  'finance' = 'finance',
  'governmentAndNPO' = 'governmentAndNPO',
  'healthAndFitness' = 'healthAndFitness',
  'lgbtq' = 'lgbtq',
  'motorsports' = 'motorsports',
  'musicAndAudio' = 'musicAndAudio',
  'newsAndMedia' = 'newsAndMedia',
  'tourism' = 'tourism',
}

export const sectorsPreferredOrder = [
  Sector.all,
  Sector.healthAndFitness,
  Sector.eCommerceAndRetail,
  Sector.governmentAndNPO,
  Sector.finance,
  Sector.motorsports,
  Sector.tourism,
  Sector.entertainmentAndEvents,
  Sector.eLearning,
  Sector.musicAndAudio,
  Sector.lgbtq,
  // Sector.aviation,
  // Sector.construction,
  // Sector.agriculture,
  Sector.energy,
  Sector.newsAndMedia,
]

export type SectorDefinition = {
  name: string,
  abbreviation?: string,
  slug: string,
}

export const sectorDefinitions: Record<Sector, SectorDefinition> = {
  [Sector.all]: { name: 'All Sectors', slug: 'all' },
  // [Sector.agriculture]: { name: 'Agriculture', slug: 'agriculture' },
  // [Sector.aviation]: { name: 'Aviation', slug: 'aviation' },
  // [Sector.construction]: { name: 'Construction', slug: 'construction' },
  [Sector.eCommerceAndRetail]: { name: 'eCommerce & Retail', slug: 'ecommerce-and-retail' },
  [Sector.eLearning]: { name: 'eLearning', slug: 'elearning' },
  [Sector.energy]: { name: 'Energy', slug: 'energy' },
  [Sector.entertainmentAndEvents]: { name: 'Entertainment & Events', slug: 'entertainment-and-events' },
  [Sector.finance]: { name: 'Finance', slug: 'finance' },
  [Sector.governmentAndNPO]: { name: 'Government & NPO', abbreviation: 'Gov & NPO', slug: 'government-and-npo' },
  [Sector.healthAndFitness]: { name: 'Health & Fitness', slug: 'health-and-fitness' },
  [Sector.lgbtq]: { name: 'LGBTQ+', slug: 'lgbtq' },
  [Sector.motorsports]: { name: 'Motorsports', slug: 'motorsports' },
  [Sector.musicAndAudio]: { name: 'Music & Audio', slug: 'music-and-audio' },
  [Sector.newsAndMedia]: { name: 'News & Media', slug: 'news-and-media' },
  [Sector.tourism]: { name: 'Tourism', slug: 'tourism' },
}

export const isSectorEnum = (s: string): s is Sector => s in Sector;

export const getSectorName = (s?: Sometimes<Sector | string>) => {
  if (!s) return undefined;
  if (isSectorEnum(s)) return sectorDefinitions[s].name;
  const [sector, def] = Object.entries(sectorDefinitions).find(([key, value]) => value.slug === s || value.name === s || value.abbreviation === s) || [];
  return def?.name;
}
export const getSectorFromSlug = (s?: Sometimes<string>) => {
  const [sector, def] = Object.entries(sectorDefinitions).find(([key, value]) => value.slug === s) || [];
  return sector as Sector | undefined;
}