import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { AaaidpAnimationImagesQuery } from "../../types/generated";

export const ProjectMetaInfoUrgentAnimation: ProjectMetaInfo = {
  name: "urgent-animation",
  displayName: "Urgent Animation",
  abbreviation: "Urgent Animation",
  subtitle: "Animation produced for Urgent.ie",
  // TODO
  description: "Animation produced for Urgent.ie.",
  categories: [
    Category.design,
  ],
  sectors: [
    Sector.eCommerceAndRetail,
  ],
  hashtags: [
    Hashtag.animations,
  ],
  hasPage: true,
  keywords: [
    'Urgent.ie',
    'animation design',
    'advertisement design',
    'motion graphics',
  ],
}
export const useUrgentAnimationInfo = () => {
  const UrgentAnimationImages: UrgentAnimationImagesQuery = useStaticQuery(graphql`
    query UrgentAnimationImages {
      featuredImage: file(relativePath: {eq: "images/urgent-animation/urgent-animation-featured-image.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      pageBackgroundImage: file(relativePath: {eq: "images/urgent-animation/urgent-animation-background.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
    }
  `)
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoUrgentAnimation,
    featuredImage: {
      image: UrgentAnimationImages.featuredImage!.childImageSharp,
      title: 'Urgent Video Preview',
      alt: 'Urgent Video Preview Image',
    },
    pageBackgroundImage: {
      image: UrgentAnimationImages.pageBackgroundImage!.childImageSharp,
      title: 'Urgent Video Preview',
      alt: 'Urgent Video Preview Image',
    },
  }
  return info;
}