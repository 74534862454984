import { Expo, TweenMax } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { when } from "mobx";
import { PageSectionContext, usePageSectionContext } from "../components/PageSection/PageSection";
import { useAppContext } from '../controllers/app.controller';
import { isBrowser, isProduction } from "../env";
import { makeDisposerController } from "../utils/disposer.utils";
import { waitForFontsToLoad } from "../utils/fonts.utils";
import { log } from '../utils/loggers.utils';
import { highPerf } from '../utils/performance.utils';
import { isObject } from "../utils/typeChecks.utils";
import tick from "../utils/waiters.utils";
import { useOnMount } from "./lifecycle.hooks";
import { ObservableRef } from "./useObservableRef.hook";

export type AnimateTextEnterByLineProps = {
  animateTextEnter?: {
    delay?: number,
    onlyWhenParentSectionVisible?: boolean,
  } | true,
}

export const useAnimateTextEnterByLine = (
  ref: ObservableRef, 
  props: AnimateTextEnterByLineProps,
  context?: PageSectionContext,
) => {
  const { UI } = useAppContext();
  const autoContext = usePageSectionContext();
  const pageSection = context ?? autoContext;
  useOnMount(() => {
    if (isProduction && UI.isFirstLoad) return;
    if (!highPerf) return;
    const d = makeDisposerController();
    const animateIn = async () => {
      if (isBrowser) {
        await waitForFontsToLoad();
        if (!ref.current) return;
        const spiltText = new SplitText(ref.current.children, { type: 'lines' });
        TweenMax.set(spiltText.lines, { opacity: 0 });
        if (isObject(props.animateTextEnter)) {
          if (props.animateTextEnter.delay) {
            await tick(props.animateTextEnter.delay);
          }
          if (props.animateTextEnter.onlyWhenParentSectionVisible) {
            await when(() => pageSection.visible);
            log('visible now', pageSection.id);
          }
        }
        if (!ref.current) return;
        const styles = getComputedStyle(ref.current);
        const YOffset = parseInt(styles.fontSize ?? 64) * 1;
        TweenMax.fromTo(spiltText.lines!, .62, {
          opacity: 0,
          y: YOffset,
        }, {
          opacity: 1,
          y: 0,
          ease: Expo.easeOut,
          stagger: .05,
          onComplete: () => spiltText.revert()
        });
      }
    }
    if (props.animateTextEnter) animateIn();
    return d.disposer
  })
}